import React, { useState } from "react";
import { Transition } from "@headlessui/react";
import { RoutesNav } from "../routes-nav";
import { Link, useLocation } from "react-router-dom";
import SubscribeModal from "./subscribeModal";

function Navbar() {
    const [isOpen, setIsOpen] = useState(false);
    const [openSubscribeModal, setOpenSubscribeModal] = useState(false);

    const location = useLocation();

    return (
        <>
            <nav className="bg-black/90 fixed z-10 w-full">
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="flex items-center h-16">
                        <div className="flex-shrink-2 sm:flex-shrink-0">
                          <Link to="/">
                              <h1 className="font-bruno text-xl sm:text-2xl text-gym-red">Aleš Bursa</h1>
                          </Link>
                        </div>
                        <div className="flex items-center justify-center w-full lg:mr-60">
                            <div className="hidden md:hidden xl:flex ">
                                <div className="ml-10 items-baseline space-x-4">
                                    {
                                        RoutesNav.map((item, id) => (
                                            item.path === location.pathname ?
                                                (
                                                    <a
                                                        key={id}
                                                        className="bg-gym-red duration-300 text-black hover:bg-black hover:text-gym-red px-3 py-2 text-sm font-medium"
                                                    >
                                                        {item.name}
                                                    </a>
                                                ) :
                                                (
                                                    <Link to={item.path} key={id}>
                                                        <span
                                                            className="text-white duration-300 hover:bg-gym-red hover:text-black px-3 py-2 text-sm font-medium"
                                                        >
                                                            {item.name}
                                                        </span>
                                                    </Link>
                                                )
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="md:mr-9 sm:mr-9 xl:mr-0">
                            <button className="duration-300 text-gym-red bg-transparent border-2 hover:bg-gym-red hover:text-white border-gym-red font-bold text-sm py-2 px-4" onClick={() => setOpenSubscribeModal(true)}>Odebírat</button>
                        </div>
                        <div className="xl:hidden">
                            <button
                                onClick={() => setIsOpen(!isOpen)}
                                type="button"
                                className="inline-flex items-center justify-center p-2 text-white hover:text-gym-red focus:outline-none"
                                aria-controls="mobile-menu"
                                aria-expanded="false"
                            >
                                <span className="sr-only">Open main menu</span>
                                {!isOpen ? (
                                    <svg
                                        className="block h-6 w-6"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M4 6h16M4 12h16M4 18h16"
                                        />
                                    </svg>
                                ) : (
                                    <svg
                                        className="block h-6 w-6"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
                <Transition
                    show={isOpen}
                    enter="transition ease-out duration-300 transform"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="transition ease-in duration-200 transform"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    {(ref) => (
                        <div className="xl:hidden" id="mobile-menu">
                            <div ref={ref} className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                                {
                                    RoutesNav.map((item, id) => (
                                        item.path === location.pathname ?
                                            (
                                                <a
                                                    key={id}
                                                    className="bg-gym-red duration-300 hover:text-gym-red hover:bg-black cursor-pointer text-black block px-3 py-2 text-center text-base font-medium"
                                                    onClick={() => setIsOpen(false)}
                                                >
                                                    {item.name}
                                                </a>
                                            ) :
                                            (
                                                <Link to={item.path} onClick={() => setIsOpen(false)} key={id}>
                                                    <a
                                                        className="text-white duration-300 hover:bg-gym-red hover:text-black block px-3 py-2 text-center text-base font-medium"
                                                    >
                                                        {item.name}
                                                    </a>
                                                </Link>
                                            )
                                    ))
                                }
                            </div>
                        </div>
                    )}
                </Transition>
            </nav>
            {openSubscribeModal && <SubscribeModal setOpenSubscribeModal={setOpenSubscribeModal}/>}
        </>
    );
}

export default Navbar;
