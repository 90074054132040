import right_arrow from "../icons/right_arrow_512px.png";
import { Link } from "react-router-dom";

function Article({ pictureLink, articleName, articleText, articleDate, id }) {
    return (
        <Link
          to={`/articles/${id}`}
          className="relative flex flex-col bg-white bg-clip-border text-black shadow-md">
            <div className="relative m-0 overflow-hidden rounded-none bg-transparent bg-clip-border text-black font-bold shadow-none">
                <img
                    src={pictureLink === "" ? "https://wallpaperaccess.com/full/1078069.jpg" : pictureLink}
                    className="w-full"
                    alt="Image"
                />
            </div>
            <div className="p-6 flex-grow">
                <h4 className="block font-sans text-lg lg:text-xl xl:text-2xl font-semibold leading-snug tracking-normal antialiased">
                    {articleName}
                </h4>
                <p className="mt-3 block font-sans text-sm lg:text-lg xl:text-xl font-normal leading-relaxed antialiased">
                    {articleText}
                </p>
            </div>
            <div className="flex items-center justify-between p-6 ">
                <div className="flex items-center -space-x-4">
                    <p className="">{articleDate}</p>
                </div>
                <div className="md:text-2xl text-gym-red pb-1">
                    Více info <img className="h-5 w-5 inline" src={right_arrow} alt="Obrázek" />
                </div>
            </div>
        </Link>
    );
}

export default Article;
