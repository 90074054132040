import {useState} from "react";
import {addDoc, collection} from "@firebase/firestore";
import {db} from "../firebase-config";

function Newsletter_v1() {
  const [newsletterField, setNewsletterField] = useState("");
  const usersCollectionEmails = collection(db, "emails");
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const sendEmailToDB = async () => {
    if (emailRegex.test(newsletterField)) {
      await addDoc(usersCollectionEmails, {email: newsletterField})
    } else {
      alert("Špatný format emailu")
    }
    setNewsletterField(string => "")
  };

  return (
    <>
      <div className="flex flex-col items-center gap-6 my-36">
        <h2 className="text-4xl mx-8 font-bold text-center">Odebírej naše Fitness Novinky</h2>
        <div className="w-[5vw] h-0.5 bg-gym-red"></div>
        <p className="w-[50vw] lg:w-[40vw] text-center text-xl font-medium">
         Připoj se k nám a získej pravidelné informace o novinkách, akcích a slevách. <br/>
         Naše novinky jsou zasílány jednou za čas.
        </p>
        <div className="flex flex-col items-center md:flex-row gap-3">
          <input
            className="w-[50vw] md:w-[30vw] bg-[#323232] px-4 py-2"
            type="email"
            placeholder="E-mail"
            value={newsletterField}
            onChange={(e) => setNewsletterField(e.target.value)}
          />
          <button className="w-[24vw] md:w-[12vw] font-bold border-2 border-gym-red py-2 max-w-[175px] background-color duration-300 bg-gym-red text-black hover:text-gym-red hover:bg-transparent" onClick={sendEmailToDB}>
            Odebírat
          </button>
        </div>
      </div>
    </>
  );
}

export default Newsletter_v1;
