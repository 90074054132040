import { useEffect, useState } from "react";
import { addDoc, updateDoc, collection } from "@firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { db, storage } from "../firebase-config";
import {getDocs} from "firebase/firestore";

import ReactDOM from "react-dom";
import MDEditor, { selectWord } from "@uiw/react-md-editor";

function ArticleForm() {
  const [articleHeadingField, setArticleHeadingField] = useState("");
  const [articleTextField, setArticleTextField] = useState("");
  const [articleYoutubeField, setArticleYoutubeField] = useState("");
  const [articleCardPictureField, setArticleCardPictureField] = useState("");
  const [articleCardTextField, setArticleCardTextField] = useState("");
  const [emailsFromFirestore, setEmailsFromFirestore] = useState([]);
  const [emailsBool, setEmailsBool] = useState(false);
  const [emailsClipboard, setEmailsClipboard] = useState("");
  const [boolean, setBoolean] = useState(false);
  const [imagesForStorage, setImagesForStorage] = useState([]);
  const [pdfForStorage, setPdfForStorage] = useState([]);
  const usersCollectionArticle = collection(db, "testing");
  const usersCollectionArticleCard = collection(db, "testingCard");
  const usersCollectionEmails = collection(db, "emails");

  const [value, setValue] = useState("");

  const addLineBreaks = (text) => {
    return text.replace(/\n/g, "\n");
  };

  const getEmails = async () => {
    await getDocs(usersCollectionEmails)
        .then((querySnapshot)=>{
          querySnapshot.forEach((doc) => {
            setEmailsFromFirestore(oldArray => [...oldArray, doc.data().email])
          });
        })
  }

  const emailsToText = () => {
    emailsFromFirestore.forEach((email) => (
        setEmailsClipboard(old => old + email + " ")
    ))
  }

  const viewEmails = () => {
    setEmailsBool(true)
  }

  useEffect(() => {
    getEmails()
  }, []);

  useEffect(() => {
    emailsToText()
  }, [emailsFromFirestore]);

  const onImageChange = (e) => {
    setImagesForStorage([...e.target.files])
  }

  const onPdfChange = (e) => {
    setPdfForStorage([...e.target.files])
  }

  const uploadImage = async (image) => {
    const storageRef = ref(storage, `/images/${Date.now()}-${image.name}`);

    const response = await uploadBytes(storageRef, image);
    const url = await getDownloadURL(response.ref);
    return url;
  }

  const uploadImages = async (images) => {
    const imagePromises = Array.from(images, (image) => uploadImage(image));

    const imageRes = await Promise.all(imagePromises);
    setImagesForStorage([])
    return imageRes; // list of url like ["https://..", ...]
  }

  const uploadPdf = async (pdf) => {
    const storageRef = ref(storage, `/pdf/${Date.now()}-${pdf.name}`);

    const response = await uploadBytes(storageRef, pdf);
    const url = await getDownloadURL(response.ref);
    return url;
  }

  const uploadPdfs = async (pdfs) => {
    const pdfPromises = Array.from(pdfs, (pdf) => uploadPdf(pdf));

    const pdfRes = await Promise.all(pdfPromises);
    setPdfForStorage([])
    return pdfRes; // list of url like ["https://..", ...]
  }

  const submit = () => {
    createArticle();
    setArticleYoutubeField("");
    setArticleTextField("");
    setArticleHeadingField("");
    setArticleCardPictureField("");
    setArticleCardTextField("");
  };

  const createArticle = async () => {
    const date = new Date();

    //await addDoc(usersCollectionArticle, {heading: articleHeadingField, text: addLineBreaks(articleTextField), youtubeUrl: articleYoutubeField, date: date.toLocaleDateString()})
    const docRef = await addDoc(usersCollectionArticle, {});
    await addDoc(usersCollectionArticleCard, {
      id: docRef.id,
      heading: articleHeadingField,
      text: articleCardTextField,
      pictureUrl: articleCardPictureField,
      date: date.toLocaleDateString(),
    });
    await updateDoc(docRef, {
      heading: articleHeadingField,
      text: addLineBreaks(articleTextField),
      youtubeUrl: articleYoutubeField,
      date: date.toLocaleDateString(),
    });
  };

  useEffect(() => {
    setBoolean(
      articleTextField === "" ||
        articleHeadingField === "" ||
        articleCardTextField === ""
    );
  }, [
    articleHeadingField,
    articleTextField,
    articleCardTextField,
  ]);

  return (
    <div className="grid place-items-center mt-20 mb-20">
      <div className="w-3/4 md:w-3/5">
        <div>
          <h1 className="text-2xl sm:text-4xl tracking-wide uppercase text-white text-left mb-8">
            Článek
          </h1>
          <h1 className="text-sm sm:text-xl tracking-wide uppercase text-gym-red text-left">
            Nadpis článku
          </h1>
          <input
            type="text"
            className="
                            bg-field-grey placeholder-field-white
                            w-full p-2.5 mt-2
                        "
            value={articleHeadingField}
            maxLength={40}
            placeholder="Nadpis (povinné) - max. 40 znaků"
            onChange={(e) => setArticleHeadingField(e.target.value)}
          />
          <h1 className="text-sm sm:text-xl tracking-wide uppercase text-gym-red text-left mt-6">
            YouTube video
          </h1>
          <input
            type="text"
            className="
                            bg-field-grey placeholder-field-white
                            w-full p-2.5 mt-2
                        "
            value={articleYoutubeField}
            placeholder="Přidej YouTube video (nepovinné)"
            onChange={(e) => setArticleYoutubeField(e.target.value)}
          />
          <h1 className="text-sm sm:text-xl tracking-wide uppercase text-gym-red text-left mt-6 mb-2">
            Obsah článku
          </h1>
          <MDEditor
              height={500}
              value={articleTextField}
              onChange={setArticleTextField}
          />
          <h1 className="text-2xl sm:text-4xl tracking-wide uppercase text-white text-left mb-8 mt-8">
            Karta pro článek
          </h1>
          <h1 className="text-sm sm:text-xl tracking-wide uppercase text-gym-red text-left mt-6">
            Odkaz na obrázek
          </h1>
          <input
            type="text"
            className="
                            bg-field-grey placeholder-field-white
                            w-full p-2.5 mt-2
                        "
            value={articleCardPictureField}
            placeholder="Přidej odkaz na obrázek (nepovinné)"
            onChange={(e) => setArticleCardPictureField(e.target.value)}
          />
          <h1 className="text-sm sm:text-xl tracking-wide uppercase text-gym-red text-left mt-6">
            Shrnutí článku pro kartu
          </h1>
          <textarea
            className="
                            bg-field-grey placeholder-field-white
                            w-full p-2.5 mt-2 h-96 min-h-[200px]
                        "
            value={articleCardTextField}
            maxLength={160}
            placeholder="Shrnutí článku pro kartu. Velice krátce. (povinné) - max. 160 znaků"
            onChange={(e) => setArticleCardTextField(e.target.value)}
          />
          <button
            className={`${
              boolean
                ? "bg-gym-red/20"
                : "bg-gym-red hover:text-gym-red hover:bg-black"
            } text-black font-bold mt-6 py-2 px-4`}
            disabled={boolean}
            onClick={submit}
          >
            Vytvořit
          </button>
        </div>
        <div className="mt-20">
          <h1 className="text-2xl sm:text-4xl tracking-wide uppercase text-white text-left mb-8 mt-8">
            Nahrávání obrázků
          </h1>
          <div className="flex items-center justify-center w-full mb-2">
            <label htmlFor="dropzone-picture"
                   className="flex flex-col items-center justify-center w-full h-64 border-2 border-field-white border-dashed cursor-pointer bg-field-grey">
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <svg aria-hidden="true" className="w-10 h-10 mb-3 text-gym-red" fill="none" stroke="currentColor"
                     viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                </svg>
                <p className="mb-2 text-sm text-gym-red"><span className="font-semibold">Click to upload</span></p>
                <p className="text-xs text-field-white">SVG, PNG, JPG (MAX. 800x400px)</p>
                <p className="text-xs text-field-white">Počet nahraných souborů: {imagesForStorage.length}</p>
              </div>
              <input id="dropzone-picture" type="file" multiple accept="image/*" className="hidden" onChange={onImageChange} />
            </label>
          </div>
          <button
              className={`${
                  imagesForStorage.length === 0
                      ? "bg-gym-red/20"
                      : "bg-gym-red hover:text-gym-red hover:bg-black"
              } text-black font-bold mt-6 py-2 px-4`}
              disabled={imagesForStorage.length === 0}
              onClick={() => uploadImages(imagesForStorage)}
          >
            Odeslat obrázky
          </button>
        </div>
        <div className="mt-20">
          <h1 className="text-2xl sm:text-4xl tracking-wide uppercase text-white text-left mb-8 mt-8">
            Nahrávání PDF souborů
          </h1>
          <div className="flex items-center justify-center w-full mb-2">
            <label htmlFor="dropzone-file"
                   className="flex flex-col items-center justify-center w-full h-64 border-2 border-field-white border-dashed cursor-pointer bg-field-grey">
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <svg aria-hidden="true" className="w-10 h-10 mb-3 text-gym-red" fill="none" stroke="currentColor"
                     viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                </svg>
                <p className="mb-2 text-sm text-gym-red"><span className="font-semibold">Klikni a nahraj</span></p>
                <p className="text-xs text-field-white">.pdf</p>
                <p className="text-xs text-field-white">Počet nahraných souborů: {pdfForStorage.length}</p>
              </div>
              <input id="dropzone-file" type="file" multiple accept="application/pdf" className="hidden" onChange={onPdfChange} />
            </label>
          </div>
          <button
              className={`${
                  pdfForStorage.length === 0
                      ? "bg-gym-red/20"
                      : "bg-gym-red hover:text-gym-red hover:bg-black"
              } text-black font-bold mt-6 py-2 px-4`}
              disabled={pdfForStorage.length === 0}
              onClick={() => uploadPdfs(pdfForStorage)}
          >
            Odeslat PDF
          </button>
        </div>
        <div className="mt-20">
          <h1 className="text-2xl sm:text-4xl tracking-wide uppercase text-white text-left mb-8 mt-8">
            Zobrazit emaily
          </h1>
          <div className="flex">
            <button
                className="bg-gym-red hover:text-gym-red hover:bg-black text-black font-bold mt-6 py-2 px-4 mr-2"
                onClick={viewEmails}
            >
              Zobrazit emaily
            </button>
            {
              emailsBool
                ?
                  <button
                      className="bg-gym-red hover:text-gym-red hover:bg-black text-black font-bold mt-6 py-2 px-4"
                      onClick={() => navigator.clipboard.writeText(emailsClipboard)}
                  >
                    Kopírovat emaily
                  </button>
                :
                  null
            }
          </div>
          {
            !emailsBool
              ?
                null
              :
                <div className="bg-field-grey text-field-white w-full p-4 mt-2 h-96 min-h-[200px] text-ellipsis overflow-hidden">
                  <p>
                    {
                      emailsFromFirestore.map((email) => (
                          email + " "
                      ))
                    }
                  </p>
                </div>
          }
        </div>
      </div>
    </div>
  );
}

export default ArticleForm;
