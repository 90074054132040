import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {db} from "../firebase-config"
import { getDoc, doc } from "@firebase/firestore";
import AboveSection from "../components/aboveSection";
import YoutubeVideo from "../components/youtubeVideo";
import {Helmet} from "react-helmet-async";
import showdown from "showdown";

function DetailPageArticle() {
    const { id } = useParams();
    const [data, setData] = useState(null);
    const usersDocRef = doc(db, "testing", id);

    const converter = new showdown.Converter();

    useEffect(() => {
        const getArticle = async () => {
            const data = await getDoc(usersDocRef)
            if (data.exists) {
                setData(data.data())
            }
        }
        getArticle()
    }, [id]);

    if (!data) {
        return <p>Loading...</p>;
    }

    return (
        <>
            <Helmet>
                <title>{data.heading}</title>
                <meta
                    name="description"
                    content="Prohlédni si mé nejaktuálnější příspěvky v podobě článků."
                />
                <link rel="canonical" href="/articles/:id"/>
            </Helmet>
            <div>s
                <AboveSection textWhite={data.heading} />
                <div className="grid place-items-center my-20 mx-5">
                    <div className="prose prose-invert xl:prose-xl 2xl:prose-2xl">
                        <div dangerouslySetInnerHTML={{__html: converter.makeHtml(data.text)}}/>
                        {
                            data.youtubeUrl ?
                                <YoutubeVideo embedId={data.youtubeUrl} />
                                :
                                null
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default DetailPageArticle;
