import { HashLink as Link } from 'react-router-hash-link';

function landingCard({ colorStyle, icon, heading, text }) {
    return (
        <Link to="/training-plan#trainingPlans" className="max-w-[600px]" >
            <div className={`${colorStyle ? "bg-black" : "bg-gym-red"} z-0 p-4 md:p-6 duration-300 transition ease-in-out hover:scale-105`}>
                <div className="grid grid-cols-2">
                    <div className={`${colorStyle ? "border-t-gym-red" : "border-t-black"} w-0 h-0  
                        border-r-[92px] xl:border-r-[128px] border-r-transparent
                        border-l-[0px] border-l-transparent
                        border-t-[92px] xl:border-t-[128px]`}>
                    </div>
                    <img className="" src={icon}></img>
                </div>
                <div className="">
                    <h3 className="text-2xl ">{heading}</h3>
                    <p className="text-gray-300 mt-4">{text}</p>
                </div>
            </div>
        </Link>
    );
}

export default landingCard;
