import StartTrainingWithAles from "../components/startTrainingWithAles";
import VerticalTimecard from "../components/verticalTimecard";
import TrainingDietCard from "../components/trainingDietCard";
import MyArticles from "../components/myArticles";
import Newsletter_v1 from "../components/newsletter_v1";
import Agenda from "../components/agenda";
import {Helmet} from "react-helmet-async";
import {useEffect} from "react";

function Home() {
  return (
      <>
        <Helmet>
            <title>O nás</title>
            <meta
                name="description"
                content="Mrkni na to zda-li by se ti líbilo trénovat s námi."
            />
            <link rel="canonical" href="/"/>
        </Helmet>
        <div>
          <StartTrainingWithAles />
          <TrainingDietCard />
          <Agenda
            agednaHeading01="Vaše Tělesná transformace je na dosah!"
            agednaText01="Vítejte na našem webu, na který Vás pravděpodobně přivedla Vaše touha změnit své tělo a dosáhnout tak tělesné a výkonnostní transformace a lepšího zdraví."
            agednaHeading02="Jste tady naprosto správně, protože i když jste již mohli slyšet ledacos, málokde jsou informace a pomoc uspořádány a podány jako zde."
            agednaText02="Především v dnešní době zahlcení příliš mnoha informacemi. Uvažujete o nějaké pozitivní tělesné změně, nebo už jste se o ni pokoušeli, ale příliš efektivní to nebylo? Nebo jste něčeho zajímavého dosáhli, ale třeba se to nepodařilo udržet?
            "
            agednaText03="Představte si, kdybyste se v celém tématu skvěle vyznali a nic by nebránilo se do jednotlivých kroků a fází vpustit tak, že už by se Vám díky výsledkům ani nechtělo přestávat! Pokud zná Člověk dopředu úspěšný plán akce a rozumí proč to tak je, motivace roste tak velkým tempem, jako výsledky samotné. Po dosažení významných kroků totiž už Člověk nedovolí aby se snadno dostal na začátek.
            Možná také patříte k Těm, kteří jsou celkem pokročilí a hledáte možnosti, jak se posunout dále z případných stagnací.
            "
            agednaHeading03="Ať je to jakkoliv, zde právě naleznete informace a řešení všech úrovní. "
            agednaText04="Věnujeme se tu především poctivému informování a vzdělávání Lidí a získávání lepšího přehledu v problematice Fitness proměn. Právě v této oblasti nabízíme komplexní řešení, tak, abychom zajistili co nejefektivnější.
            "
            agednaText05="Abyste dokázali všechno dotáhnout do co nejzdárnějšího konce, je nejlepší mít plán v každé oblasti metodiky. To znamená výživový a tréninkový plán. Tyto plány mají mnoho společného, ale přesto musí být tak individuální jako každý Člověk se svými specifiky. "
            agednaText06=" Každý plán se musí umět to nejlépe přenést do reálného prostředí, aby mohl být úspěšný. Co se týká tréninkové metodiky, kromě plánů nabízíme i možnosti osobních tréninků, pokud máte zájem o co nejkomplexnější a nejlepší řešení. "
            agednaHeading04="Doporučuji všem, aby si přečetli články (jsou zdarma), do nichž jsme věnovali velké úsilí."
            agednaText07="Jejich pomocí se snadno a rychle přenesete do hlubší úrovně vhledu a zároveň získáte jistý nadhled. Budete tedy lépe vědět, co Vám mohou řešení přinést. Pro začátek je vhodný třeba tento článek “Jak se vyznat v tělesných proměnách”.
            Pokud budete mít jasno a chtěli byste začít, stačí si jen naše plány objednat, vyplnit dotazníky a pustit se do akce!
            Přejeme radost z poznání, konání, a především z výsledků!!
            "
          />
          <Newsletter_v1 />
          <VerticalTimecard />
          <MyArticles />
        </div>
      </>
  );
}

export default Home;
