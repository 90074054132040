import React from "react";
import { Link } from "react-router-dom";

export default function Page404() {

    return (
        <div className="container flex items-center justify-center min-h-screen px-6 py-12 mx-auto">
            <div className="w-full ">
                <div className="flex flex-col items-center max-w-lg mx-auto text-center">
                    <p className="text-6xl font-medium text-gym-red mb-6">404 error</p>
                    <h1 className="mt-3 text-2xl font-semibold text-white md:text-3xl my-3">We lost this
                        page</h1>
                    <p className="mt-4 text-xd-gray">We searched high and low, but couldn’t find
                        what you’re looking for.Let’s find a better place for you to go.</p>

                    <div className="flex items-center w-full mt-6 gap-x-3 shrink-0 sm:w-auto">
                        <Link
                            to="/"
                            className="duration-300 hover:text-gym-red hover:bg-transparent border-2 bg-gym-red text-white border-gym-red font-bold text-sm py-2 px-4"
                        >
                            Take me home
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}