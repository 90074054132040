import { Link } from "react-router-dom";
import {ref, getDownloadURL} from "firebase/storage";
import {storage} from "../firebase-config";
import {useEffect, useState} from "react";

function StartTrainingWithAles() {

  const imgRef = ref(storage, 'images/s-36.jpg');
  const [imgUrl, setImgUrl] = useState("")

  const callImg = () => {
    getDownloadURL(imgRef)
        .then((url) => {
          setImgUrl(url)
        })
        .catch((error) => {
          console.log(error)
        });
  }

  useEffect(() => {
    callImg()
  }, [])

  return (
    <div
      className="h-screen relative z-0 flex flex-col justify-center bg-cover bg-center z-0 "
      style={{
        backgroundImage:
          `url(${imgUrl})`,
      }}
    >
      <div className="bg-black/70 inset-0 absolute"></div>
      <div className="relative z-10 flex flex-col items-center">
        <Link
          to="/training-plan"
          className="text-white text-5xl sm:text-6xl lg:text-7xl mb-8 sm:mb-2 font-semibold text-center"
        >
          Je na čase jít něco dělat.
          <br />
          <div className="text-gym-red mt-5">Změň svůj život!</div>
        </Link>
        <p className="text-field-white text-center text-sm mb-14 sm:text-base md:text-lg lg:text-xl md:mt-10 mx-2">
          Tělesné změny chytřeji a efektivněji. Rozhodni se jednat a máš řešení nadosah!
        </p>
        <Link
          to="/training-plan"
          className="duration-300 border-2 border-gym-red bg-gym-red text-white hover:bg-transparent px-8 py-4 text-3xl font-bold"
        >
          Začni makat
        </Link>
      </div>
    </div>
  );
}

export default StartTrainingWithAles;
