import React, { useEffect, useState } from "react";

function YoutubeVideo({ embedId,header }) {
  // const [url, setUrl] = useState("")

  // useEffect(() => {
  //   setUrl(embedId.split('=')[1])
  // }, [])

  return (
    <div>
      <div className="text-4xl flex justify-center my-32">{header}</div>
      <div className="overflow-hidden relative h-0 pb-[56.25%] flex justify-center items-center">
        <iframe
          className="top-0 h-[80%] w-[80%] absolute"
          width="853"
          height="480"
          src={`https://www.youtube.com/embed/${embedId}`}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
          allowFullScreen
          title="Embedded youtube"
        />
      </div>
    </div>
  );
}

export default YoutubeVideo;
