import { useState } from "react";
import { addDoc, collection } from "@firebase/firestore";
import { db } from "../firebase-config";

function Footer() {
  const [newsletterField, setNewsletterField] = useState("");
  const usersCollectionEmails = collection(db, "emails");
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const sendEmailToDB = async () => {
    if (emailRegex.test(newsletterField)) {
      await addDoc(usersCollectionEmails, { email: newsletterField })
    } else {
      alert("Špatný format emailu")
    }
    setNewsletterField("")
  };

  return (
    <footer className="px-4 divide-y divide-field-white bg-black mt-auto">
      <div className="container flex flex-col justify-between py-10 mx-auto space-y-8 lg:flex-row lg:space-y-0">
        <div className="lg:w-1/3">
          <h1 className="font-bruno text-3xl text-left text-gym-red">
            Aleš Bursa
          </h1>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 text-sm gap-x-3 gap-y-8 lg:w-2/3 md:grid-cols-3">
          <div className="space-y-3">
            <h1 className="text-sm sm:text-xl tracking-wide uppercase text-gym-red">
              Kontakt
            </h1>
            <ul className="space-y-1 uppercase">
              <li>
                <a rel="noopener noreferrer" href="#">
                  +420 732 732 503
                </a>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  href="mailto:dvoraksebastien@gmail.com"
                >
                  ales.bursa.pt@gmail.com
                </a>
              </li>
            </ul>
          </div>
          <div className="space-y-3">
            <h1 className="text-sm sm:text-xl tracking-wide uppercase text-gym-red">
              Sociální Sítě
            </h1>
            <ul className="space-y-1 uppercase">
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.instagram.com/ales_bursa/"
                >
                  Instagram
                </a>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.facebook.com/alesbursapt"
                >
                  Facebook
                </a>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.youtube.com/@AlesBursa"
                >
                  Youtube
                </a>
              </li>
            </ul>
          </div>
          <div className="space-y-3">
            <h1 className="text-sm sm:text-xl tracking-wide uppercase text-gym-red text-left">
              Odebírej <br />
              newsletter
            </h1>
            <div className="flex justify-start space-x-3">
              <input
                type="text"
                className="bg-field-grey placeholder-field-white w-1/2
                                block sm:w-full p-2.5"
                placeholder="E-mail"
                value={newsletterField}
                onChange={(e) => setNewsletterField(e.target.value)}
              />
            </div>
            <button className="duration-300 bg-gym-red text-black hover:text-gym-red hover:bg-transparent border-2 border-gym-red font-bold py-2 px-4" onClick={sendEmailToDB}>Odebírat</button>
          </div>
        </div>
      </div>
      <div className="py-6 text-sm text-center text-field-white">
        © 2023 Aleš Bursa. All rights reserved.
      </div>
    </footer>
  );
}

export default Footer;
