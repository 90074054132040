import ReviewCard from "../components/reviewCard";
import Pricing from "../components/pricing";
import Pricing2 from "../components/pricing2";
import AboveSection from './../components/aboveSection';
import {Helmet} from "react-helmet-async";


function TrainingPlan() {
    return (
        <>
            <Helmet>
                <title>Tréninkový plán</title>
                <meta
                    name="description"
                    content="Začni na sobě makat! Nabízíme rozsáhlé tréninkové plány, které ti pomůžou zhubnout, vylepšit jídelníček, nabrat svalovou hmotu a zlepšit kondici. Pokud nebudeš spokojen nabízíme refundaci peněz."
                />
                <link rel="canonical" href="/training-plan"/>
            </Helmet>
            <AboveSection textWhite="Začni" textRed="Makat" />
            <div className="bg-white text-black text-center py-24 sm:px-[10%] px-6 grid place-items-center">
                <h2 className="text-center text-3xl mx-auto font-bold pb-3 border-b-gym-red border-b-[3px]">
                    Začněte svou proměnu
                </h2>
                <div className="w-3/4 md:w-2/4 my-10">
                    <p className="text-xl font-medium">
                        Vyberte si z naší nabídky tréninkových plánů a začněte pracovat na lepší verzi sebe samých.
                        Pokud není trénink vaším hlavním zájmem, máte možnost vybrat si z našich nabízených deníků,
                        které vám mohou pomoci vést organizovanější a efektivnější život.
                        Děkujeme, že jste zavítali na naši stránku, a těšíme se na společnou cestu k dosažení vašich
                        zdravotních a kondičních cílů.
                    </p>
                </div>
            </div>
            <Pricing2 />
            <div id="trainingPlans" className="my-24">
                <Pricing/>
            </div>
        </>
    );
}

export default TrainingPlan;
