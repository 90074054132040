import { useState } from "react";
import OrderModal from "./orderModal";
import CashBackModal from "./cashBackModal";

function PricingItemImportant({ type, price,info, perkList }) {
    const perks = []

    perkList.forEach((data, id) => {
        perks.push(<li key={id}><span className="text-gym-red">&#10003;</span> {data}</li>)
    })

    const [openModal,setOpenModal] = useState(false)
    const [openCashBackModal,setOpenCashBackModal] = useState(false)

    return (
        <div className="flex flex-col items-center gap-6 p-6 max-w-[475px] border-4 border-gym-red relative">
            <div className="absolute top-[-20px] left-[-30px] bg-gym-red py-2 px-4 font-bold">
                <div className="absolute top-0 left-0 w-0 h-0
                         border-r-[12px] border-r-transparent
                         border-l-[0px] border-l-transparent
                         border-t-[12px] border-t-black
                        ">
                </div>
                Nejlepší nabídka
                <div className="absolute bottom-0 right-0 w-0 h-0
                     border-l-[12px] border-l-transparent
                     border-r-[0px] border-r-transparent
                     border-b-[12px]  border-b-black
                    "></div>
            </div>
            <div className="text-5xl font-bold">{price}Kč</div>
            <div className="bg-gym-red font-medium w-[90%] p-4 relative">
                <div className="text-lg font-medium">
                    {type}
                </div>
                <div className="text-sm">
                    {info}
                </div>
            </div>
            <div className="h-[2px] w-[100%] bg-field-grey"></div>
            <ul className="self-start text-gray-300">
                {perks.map((item)=>(<li className="my-16">{item}</li>))}
            </ul>
            <button
                className="self-end mt-auto relative border border-gym-red bg-gym-red transition duration-300 hover:bg-transparent font-bold w-[95%] p-2 drop-shadow-xd"
                onClick={()=>{setOpenModal(true)}}
                >
                <div className="absolute top-[-1px] left-[-1px] w-0 h-0
                         border-r-[12px] border-r-transparent
                         border-l-[0px] border-l-transparent
                         border-t-[12px] border-t-black
                        "
                />
                Začni Makat
                <div className="absolute bottom-[-1px] right-[-1px] w-0 h-0
                     border-l-[12px] border-l-transparent
                     border-r-[0px] border-r-transparent
                     border-b-[12px]  border-b-black
                    "
                />
            </button>
            {openModal && <OrderModal setOpenModal={setOpenModal} setOpenCashBackModal={setOpenCashBackModal} type={type} price={price}/>}
            {openCashBackModal && <CashBackModal setOpenCashBackModal={setOpenCashBackModal}/>}
        </div>
    );
}

export default PricingItemImportant;
