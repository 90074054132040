import { useState, useRef } from "react";
import emailjs from '@emailjs/browser';

function OrderModal({ setOpenModal, setOpenCashBackModal, price, type }) {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault(); // prevents the page from reloading when you hit “Send”

        emailjs.sendForm('service_zy7hvdb','template_hwxbswv', form.current, '0pRpL_VNfX10MBYWV')
            .then((result) => {
                setOpenModal(false)
                setOpenCashBackModal(true)
            }, (error) => {
                console.log(error)
            });
    };

    const onClose = () => {
        setOpenModal(false)
    }

    return (
        <div className="z-50 fixed flex flex-col justify-center items-center w-screen h-screen left-0 top-0 bg-black bg-opacity-50">
            <div className="border border-gym-red bg-black  p-4 flex flex-col">
                <form ref={form} onSubmit={sendEmail}>
                    <h1 className="text-center text-2xl mx-auto font-bold my-4">{type}</h1>
                    <input
                        type="text"
                        className="bg-field-grey placeholder-field-white w-full p-2.5 mt-2"
                        name="name"
                        //value={modalName}
                        placeholder="Jméno (povinné)"
                        //onChange={(e) => setModalName(e.target.value)}
                        required
                    />
                    <input
                        type="email"
                        className="bg-field-grey placeholder-field-white w-full p-2.5 mt-2"
                        name="email"
                        //value={modalMail}
                        placeholder="Email (povinné)"
                        //onChange={(e) => setModalMail(e.target.value)}
                        required
                    />
                    <input
                        type="number"
                        className="bg-field-grey placeholder-field-white w-full p-2.5 mt-2"
                        name="phone"
                        //value={modalMail}
                        placeholder="Telefoní číslo (nepovinné)"
                        //onChange={(e) => setModalMail(e.target.value)}
                    />
                    <input
                        className="bg-field-grey placeholder-field-white w-full p-2.5 mt-2 h-96 hidden"
                        name="subject"
                        value={type}
                        placeholder="Zadejte text (povinné)"
                    />
                    <div className="flex justify-center gap-8 lg:gap-10 pt-4">
                        <button className="text-gym-red bg-transparent border-2 hover:bg-gym-red hover:text-white border-gym-red font-bold py-2 px-4" onClick={() => onClose()}>Zavřít</button>
                        <input className="transition-property: background-color duration-150 bg-gym-red text-white cursor-pointer hover:bg-transparent border-2 border-gym-red font-bold py-2 px-4" type="submit" value="Objednat" />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default OrderModal;
