import PricingItem from "../components/pricingItem";

function Pricing2() {
    return (
        <>
            <div className='grid place-items-center mt-12 mb-8'>
                <h2 className="text-5xl font-semibold">Fitness deníky</h2>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-6 place-items-center pt-10 px-8">
                <PricingItem type="Deník - ženská verze" price={190} info="Tréninkový deník v žensky laděném designu" perkList={["Užitečný nástroj, který Vám pomůže přehledným způsobem zapisovat Váš progres jak v rovině fyzického tréninku, tak v rovině výživy", "Ideální pro vyhodnocování zpětné analýzy pokroku a na základě toho nastavování budoucích změn strategie programů", "Informace, bez jakýchkoliv marketingových tendencí"]} />
                <PricingItem type="Deník - mužská verze" price={190} info="Tréninkový deník v mužsky laděném designu" perkList={["Užitečný nástroj, který Vám pomůže přehledným způsobem zapisovat Váš progres jak v rovině fyzického tréninku, tak v rovině výživy", "Ideální pro vyhodnocování zpětné analýzy pokroku a na základě toho nastavování budoucích změn strategie programů", "Informace, bez jakýchkoliv marketingových tendencí"]} />
                <PricingItem type="Průvodce suplementací" price={190} info="Pomocník v porozumění oblasti výživových suplementů, které slouží jako rozšíření, či doplnění stravy" perkList={["Popisuje nejen různé kategorie produktů nezávisle na značkách výrobců, ale popisuje také motivace a důvody proč se k určitým cílům hodí konkrétní produkty, či jejich kombinace", "Naleznete si pro sebe pouze ty suplementy, které se dají strategicky použít na efektivní řešení Vašeho konkrétního cíle", "Informace, bez jakýchkoliv marketingových tendencí"]} />
            </div>
        </>
    );
}

export default Pricing2;
