import ArticleForm from "../components/articleForm";
import AboveSection from "../components/aboveSection";
import {Helmet} from "react-helmet-async";

function CreateArticle() {
    return (
        <>
            <Helmet>
                <title>Start working now!</title>
                <meta
                    name="description"
                    content="Start working on your self right now! You wont regret it and you have nothing to lose. If you wouldnt like what you did get I offer money back."
                />
                <link rel="canonical" href="/create-article"/>
                <meta name="robots" content="noindex" />
            </Helmet>
            <div>
                <AboveSection textWhite="Vytvořit" textRed="článek" />
                <ArticleForm />
            </div>
        </>
    );
}

export default CreateArticle;