function PartnersPhotos({ link, text }) {
  return (
    <div>
      <img src={link} width="250" />
      <p className="text-center">{text}</p>
    </div>
  );
}

export default PartnersPhotos;
