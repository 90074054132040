import React, {useEffect, useState} from "react";
import {storage } from "../firebase-config";
import { ref, listAll, getDownloadURL } from "firebase/storage";

import ImageGallery from "react-image-gallery";
import "../../node_modules/react-image-gallery/styles/css/image-gallery.css";

function LightGallery() {
    const listRef = ref(storage, 'images/');
    const [data, setData] = useState([])

    // List All Files
    const listItem = () => {
        listAll(listRef)
            .then((res) => {
                res.items.forEach((item) => {
                    getDownloadURL(item).then((url) => {
                        setData(arr => [...arr, {original: url, thumbnail: url}])
                    })
                });
            }).catch((error) => {
            console.log(error)
        });
    }

    useEffect(() => {
        listItem()
    }, [])

    return (
        <div className="z-30">
            <ImageGallery items={data} infinite />
        </div>
    );
}

export default LightGallery;
