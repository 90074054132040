import {getDownloadURL, ref} from "firebase/storage";
import {storage} from "../firebase-config";
import {useEffect, useState} from "react";

function AboveSection({url, textWhite, textRed}) {
    const imgRef = ref(storage, 'images/s-36.jpg');
    const [imgUrl, setImgUrl] = useState("")

    const callImg = () => {
        getDownloadURL(imgRef)
            .then((url) => {
                setImgUrl(url)
            })
            .catch((error) => {
                console.log(error)
            });
    }

    useEffect(() => {
        callImg()
    }, [])

    return (
        <div>
            <div className="bg-cover bg-left-200 lg:bg-center h-[40vh] relative z-0 flex flex-col items-center justify-center"
                 style={{backgroundImage: `url(${imgUrl})`}}>
                <div className="absolute inset-0 bg-black bg-opacity-80" />
                <div className="relative z-10 mt-20">
                    <h1 className="text-white text-center text-5xl sm:text-7xl mb-10 font-semibold">{textWhite} <span className="text-red-600">{textRed}</span></h1>
                </div>
            </div>
        </div>
    );
}

export default AboveSection;