import Article from "./article";
import {Link} from "react-router-dom";
import {query, limit, collection} from "firebase/firestore";
import {db} from "../firebase-config";
import {useEffect, useState} from "react";
import { getDocs } from "@firebase/firestore";
import React from "react";

function MyArticles() {
  const [dataFirestore, setDataFirestore] = useState([]);
  const usersDocRef = collection(db, "testingCard")
  const queryRef = query(usersDocRef, limit(3));

  //Double rendering is only in dev. It will work normally in production. It is invoking some error which I cannot find

  useEffect(() => {
      getDocs(queryRef).then((querySnapshot) => {
          querySnapshot.forEach((doc, index) => {
              console.log(index);
              setDataFirestore(oldArray => [...oldArray, doc.data()])
          });
      }).catch((error) => {
          console.log("Error getting documents: ", error);
      });
  }, [])


  if (dataFirestore.length === 0) {
      return <></>
  }
  return (
    <div className="my-20 mx-4">
      <div className="flex justify-between lg:justify-around text-gym-red sm:mx-8 lg:mx-48 relative mb-24 items-center">
        <div className="text-lg sm:text-2xl hidden md:block">
          <div className="absolute bg-gym-red w-12 h-0.5"/>Nové <br />
          Články
        </div>
        <h1 className="text-3xl sm:text-5xl text-white">Moje Články</h1>
        <div>
          <Link to="/articles" className="w-[150px] text-center text-gym-red bg-transparent border-2 hover:bg-gym-red hover:text-white border-gym-red font-bold text-lg py-2 px-4">
            Více článků
          </Link>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 xl:mx-24 gap-6">
          {
              dataFirestore.map((doc,id) => (
                  <Article
                      key={id}
                      id={doc.id}
                      pictureLink={doc.pictureUrl}
                      articleName={doc.heading}
                      articleText={doc.text}
                      articleDate={doc.date}
                  />
              ))
          }
      </div>
    </div>
  );
}

export default MyArticles;
