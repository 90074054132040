import {Link} from "react-router-dom";
import React from "react";

function Agenda(props) {
    return (
        <div className="text-xl font-medium after:block after:bg-gym-red after:w-full after:h-0.5">
            <div className="text-center bg-gym-red flex flex-col gap-10 items-center text-white text-2xl py-16 px-7">
               <h2 className="text-5xl font-semibold">{props.agednaHeading01}</h2>
               <p className="xl:w-[50%] font-normal">{props.agednaText01}</p>
            </div>
            <div className="bg-black text-white py-24 sm:px-[15%] grid grid-cols-1 xl:grid-cols-2 gap-10">
                <img className="h-full w-full xl:w-[70%] object-cover" src="https://images.unsplash.com/photo-1595078475328-1ab05d0a6a0e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=800&q=80" alt="Women training!" />
                <div className="h-full flex flex-col gap-10 px-6">
                    <h2 className="text-4xl font-bold after:content-[''] after:block after:bg-gym-red after:w-full after:h-0.5 after:mt-3">{props.agednaHeading02}</h2>
                    <p>{props.agednaText02}</p>
                    <p>{props.agednaText03}</p>
                </div>
            </div>
            <div className="bg-white text-black py-24 sm:px-[10%] px-6 grid grid-cols-1 xl:grid-cols-3 gap-10">
                <h2 className="text-center text-3xl xl:w-[50%] mx-auto font-bold xl:col-span-3 after:content-[''] after:block after:bg-gym-red after:w-full after:h-0.5 after:mt-3 ">{props.agednaHeading03}</h2>
                <p>{props.agednaText04}</p>
                <p>{props.agednaText05}</p>
                <p>{props.agednaText06}</p>
            </div>
            <div className="bg-black text-white py-24 sm:px-[15%] grid grid-cols-1 xl:grid-cols-2 grid-rows-[1fr] gap-10">
                <div className="flex flex-col gap-10 px-6">
                    <h2 className="text-4xl font-bold after:content-[''] after:block after:bg-gym-red after:w-full after:h-0.5 after:mt-3">{props.agednaHeading04}</h2>
                    <p>{props.agednaText07}</p>
                    <Link to="/articles" className="duration-300 w-[150px] text-center text-gym-red bg-transparent border-2 hover:bg-gym-red hover:text-white border-gym-red font-bold text-lg py-2 px-4">
                        Více článků
                    </Link>
                </div>
                <img className="h-full w-full object-cover hidden xl:block" src="https://images.unsplash.com/photo-1499750310107-5fef28a66643?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80" alt="Table with coffee"/>
            </div>
        </div>
    );
}

export default Agenda;
