import { useState, useRef } from "react";

function CashBackModal({setOpenCashBackModal}) {

    return (
        <div className="z-50 fixed flex flex-col justify-center items-center w-screen h-screen left-0 top-0 bg-black bg-opacity-50">
            <div className="border border-gym-red bg-black w-[50%] p-4 flex flex-col text-center">
                <h1 className="text-2xl mb-6">Vrácení peněz</h1>
                <p>
                    Jsme extrémně rádi za každého Člověka, který se na sobě rozhodne pracovat a děláme vše pro to, aby dosáhl co nejlepších a nejtrvalejších výsledků. Pokud by však nešlo vše podle Vašich představ a přání, nabízíme Vám v tom případě vracení peněz!!
                </p>
                <div className="mt-6">
                    <button className="text-gym-red bg-transparent border-2 hover:bg-gym-red hover:text-white border-gym-red font-bold py-2 px-4" onClick={() => setOpenCashBackModal(false)}>Zavřít</button>
                </div>
            </div>
        </div>
    )
}

export default CashBackModal;