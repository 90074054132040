import AboveSection from "../components/aboveSection";
import React from "react";
import {useEffect, useState} from "react";
import {collection, getDocs} from "firebase/firestore";
import {db} from "../firebase-config";
import Article from "../components/article";
import {Helmet} from "react-helmet-async";

function Articles() {
    const [dataFirestore, setDataFirestore] = useState([]);
    const usersDocRef = collection(db, "testingCard")

    //Double rendering is only in dev. It will work normally in production. It is invoking some error which I cannot find

    const getArticleCards = async () => {
        await getDocs(usersDocRef)
            .then((querySnapshot)=>{
                querySnapshot.forEach((doc) => {
                    setDataFirestore(oldArray => [...oldArray, doc.data()])
                });
            })
    }

    useEffect(() => {
        getArticleCards()
    }, []);

  return (
      <>
          <Helmet>
              <title>Fitness články</title>
              <meta
                  name="description"
                  content="Nabízím zdarma fitness články, které ti pomohou zlepšit tvůj životní styl. A to vše zdarma."
              />
              <link rel="canonical" href="/articles"/>
          </Helmet>
            <div>
                <AboveSection textWhite="Fitness články" />
                <div className="bg-white text-black text-center py-24 sm:px-[10%] px-6 grid place-items-center">
                    <h2 className="text-center text-3xl mx-auto font-bold pb-3 border-b-gym-red border-b-[3px]">
                        Rozšiřte svě vědomosti
                    </h2>
                    <div className="w-3/4 md:w-2/4 my-10">
                        <p className="text-xl font-medium">
                            Vítejte na našem webu s fitness články, kde najdete inspiraci a užitečné rady pro dosažení svých fitness cílů. Naše články vám pomohou objevit nové tréninkové strategie, tipy na zdravou výživu a cvičení.

                            Pokud hledáte inspiraci pro aktivní životní styl a lepší verzi sebe samých, jste na správném místě. Děkujeme za vaší návštěvu a těšíme se, že vám můžeme pomoci na cestě k lepší kondici a zdraví.
                        </p>
                    </div>
                </div>
                <br/><br/>
                {
                    dataFirestore.length !== 0 ?
                        <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-9 mx-10 2xl:mx-24">
                            {
                                dataFirestore.map((doc, id) => (
                                    <Article
                                        key={id}
                                        id={doc.id}
                                        pictureLink={doc.pictureUrl}
                                        articleName={doc.heading}
                                        articleText={doc.text}
                                        articleDate={doc.date}
                                    />
                                ))
                            }
                        </div>
                    :
                        <div className="text-center text-4xl text-gym-red font-bold h-[30vh]">
                            <p>Na článcích právě pracujeme...</p>
                        </div>
                }
            </div>
      </>
  );
}

export default Articles;
