import { useState } from "react";
import {addDoc, collection} from "@firebase/firestore";
import {db} from "../firebase-config";

function SubscribeModal({ setOpenSubscribeModal }) {

    const [newsletterField, setNewsletterField] = useState("");
    const usersCollectionEmails = collection(db, "emails");
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    const sendEmailToDB = async () => {
        if (emailRegex.test(newsletterField)) {
            await addDoc(usersCollectionEmails, {email: newsletterField})
            onClose()
        } else {
            alert("Špatný format emailu")
            onClose()
        }
        setNewsletterField("")
    };

    const onClose = () => {
        setOpenSubscribeModal(false)
    }

    return (
        <div className="z-50 fixed flex flex-col justify-center items-center w-screen h-screen left-0 top-0 bg-black bg-opacity-50">
            <div className="border border-gym-red bg-black max-w-[800px] w-[86%] lg:w-[50%] p-4 flex flex-col">
                <div className="text-center">
                    <div className="mb-9 mx-14">
                        <h4 className="text-xl mb-6">Odebírejte newsletter</h4>
                        <p>Zde se můžete rozhodnout odebírat můj email newsletter, ve kterém vám dám vědět nejnovější informace ze světa fitness. Stačí jen vložit svůj email!</p>
                    </div>
                    <input
                        type="text"
                        className="bg-field-grey placeholder-field-white p-2.5 mt-2 mb-4 w-[75%]"
                        name="name"
                        value={newsletterField}
                        placeholder="Zadejte email"
                        onChange={(e) => setNewsletterField(e.target.value)}
                    />
                </div>
                <div className="flex justify-center gap-10 pt-4">
                    <button className="text-gym-red bg-transparent border-2 hover:bg-gym-red hover:text-white border-gym-red font-bold py-2 px-4" onClick={() => onClose()}>Zavřít</button>
                    <button className="transition-property: background-color duration-150 bg-gym-red text-white hover:bg-transparent border-2 border-gym-red font-bold py-2 px-4" onClick={sendEmailToDB}>Odebírat</button>
                </div>
            </div>
        </div>
    )
}

export default SubscribeModal;
