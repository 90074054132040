import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import {collection, getDocs} from "firebase/firestore";
import {db} from "../firebase-config";
import {Helmet} from "react-helmet-async";


function PasswordRedirect({ pass }) {
  const [password, setPassword] = useState("");
  const [realPassword, setRealPassword] = useState();
  const navigate = useNavigate();
  const usersDocRef = collection(db, "password")

  //Double rendering is only in dev. It will work normally in production. It is invoking some error which I cannot find

  const getPassword = async () => {
    await getDocs(usersDocRef)
        .then((querySnapshot)=>{
          querySnapshot.forEach((doc) => {
            setRealPassword(doc.data().password)
          });
        })
  }

  useEffect(() => {
    getPassword()
  }, []);

  function handleNavigate() {
    pass(password === realPassword);
    navigate("/create-article");
  }

  return (
    <>
      <Helmet>
        <title>Start working now!</title>
        <meta
            name="description"
            content="Start working on your self right now! You wont regret it and you have nothing to lose. If you wouldnt like what you did get I offer money back."
        />
        <link rel="canonical" href="/login"/>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div>
        <div className="mt-[20%] grid justify-items-center">
          <label htmlFor="pass" className="text-3xl mb-6">Heslo na články</label>
          <input
            type="password"
            className="bg-field-grey placeholder-field-white p-2.5 mt-4 text-xl w-[300px] mb-4"
            placeholder="Heslo"
            required
            onChange={(e) => setPassword(e.target.value)}
          />

          <button
            value="Přihlásit"
            className=" w-[50%] md:w-[30%] xl:w-[22%] 2xl:w-[13%] h-[70%] mt-8 duration-150 text-xl bg-gym-red text-black hover:text-gym-red hover:bg-transparent border-2 border-gym-red font-bold"
            onClick={handleNavigate}
          >
            Přihlasit
          </button>
        </div>
      </div>
    </>
  );
}

export default PasswordRedirect;
