import pdfIcon from "../icons/pdf-svgrepo-com.svg";

function DownloadFile({ url, name }) {
  return (
    <a href={url} target="_blank" download={name}>
        <div className="bg-field-grey p-16 w-[260px] h-[310px] drop-shadow-sm-red drop-shadow-xl-3 duration-300 transition ease-in-out hover:scale-105 hover:drop-shadow-lg-2">
          <div className="grid place-items-center pb-6">
              <img src={pdfIcon} width="120" alt="PDF icon"/>
          </div>
          <p className="text-start font-bold">{name}</p>
        </div>
    </a>
  );
}

export default DownloadFile;
