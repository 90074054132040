import { BrowserRouter, Route, Routes, Navigate, useLocation  } from "react-router-dom";
import { useState, useEffect } from "react";
import Navbar from "./components/navbar";
import CreateArticle from "./pages/createArticle";
import { RoutesApp } from "./routes-nav.js";
import Footer from "./components/footer";
import PasswordRedirect from "./pages/passwordRedirect";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const [propData, setPropData] = useState();

  const pull_data = (data) => {
    setPropData(data);
  };

  return (
    <BrowserRouter>
      <ScrollToTop />
      <div className="min-h-[100vh] flex flex-col">
        <Navbar />
        <Routes>
          <Route
            path="/login"
            element={<PasswordRedirect pass={pull_data} />}
          />

          <Route
            path="/create-article"
            element={propData ? <CreateArticle /> : <Navigate to="/login" />}
          />

          {RoutesApp.map((item, id) => (
            <Route key={id} exact path={item.path} element={item.element} />
          ))}
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
