import LightGallery from "../components/lightGallery";
import AboveSection from "../components/aboveSection";
import {Helmet} from "react-helmet-async";
import React from "react";

function Photogallery() {
    return (
        <>
            <Helmet>
                <title>Galerie fotek</title>
                <meta
                    name="description"
                    content="Prohlédni si mé nejaktuálnější příspěvky v podobě fotek."
                />
                <link rel="canonical" href="/photogallery"/>
            </Helmet>
            <div className="">
                <div>
                    <AboveSection textWhite="Galerie" textRed="fotek" />
                    <div className="bg-white text-black text-center py-24 sm:px-[10%] px-6 grid place-items-center">
                        <h2 className="text-center text-3xl mx-auto font-bold pb-3 border-b-gym-red border-b-[3px]">
                            Fotogalerie
                        </h2>
                        <div className="w-3/4 md:w-2/4 my-10">
                            <p className="text-xl font-medium">
                                Vítejte v naší fotogalerii zaměřené na fitness, kde naleznete inspiraci a pohledy z našich tréninků a akcí. Naše fotografie zachycují úsilí a vášeň věnovanou dosažení fitness cílů. Prohlédněte si momenty, které nám pomáhají stát se zdravějšími a silnějšími.

                                Pokud hledáte motivaci pro svůj aktivní životní styl a cestu k lepší kondici, jste na správném místě. Děkujeme za vaši návštěvu a těšíme se, že vám můžeme přinést inspiraci prostřednictvím naší fotogalerie fitness.
                            </p>
                        </div>
                    </div>
                    <LightGallery />
                </div>
            </div>
        </>
    );
}

export default Photogallery;
