import PricingItem from "../components/pricingItem";
import PricingItemImportant from "../components/pricingItemImportant";

function Pricing() {
  return (
    <>
      <div className='grid place-items-center mb-8'>
        <h2 className="text-5xl font-semibold">Výživový a tréninkový plány</h2>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-4 gap-6 place-items-center pt-10 px-8">
        <PricingItem type="Tréninkový plán" price={1900} info="Tréninkový plán speciálně pro vaše osobní potřeby" perkList={["Strategický návod jak co nejefektivněji dosahovat výsledků zaměřený na individuální potřeby jednotlivce.", "Vysvětlení a doplnění konkrétních způsobů provádění cviků a jejich posloupností.", "Trénink je základ a generátor každé tělesné změny", "Je potřeba vyplnit komplexní dotazník, aby bylo vše na míru.", "Jako bonus stylizovaný zápisník progresu - Fitness Tracker."]} />
        <PricingItem type="Výživový plán" price={1900} info="Vyživový plán speciálně pro vás" perkList={["Konkrétní osobní přehled a popis toho, co jíst a doplňovat,v jakých množstvích, distribuci, a načasování. ", "Obsahuje varianty jídel na každý den, beroucí v potaz individuální potřeby jednotlivce, intolerance, ale i preference. ", "Je potřeba vyplnit komplexní dotazník, aby bylo vše na míru. ", "Jako bonus stylizovaný zápisník progresu - Fitness Tracker"]} />
        <PricingItemImportant type="Kompletní plán" price={3500} info="Plán který zahrnuje i výživu i trénink." perkList={["Zahrnuje jak výživový, tak tréninkový plán na míru", "Celkový průvodce všemi oblastmi metodiky na jednom místě", "K tomu jako bonus článek o výživových suplementech a stylizovaný zápisník progresu"]} />
        <PricingItem type="Osobní trénink" price={1900} info="Plán vedený osobním trenérem (Jen v určitých lokacích)" perkList={["Vedení osobního tréninku přímo v terénu posilovny.", "Učení se a neustálý pokrok vpřed za asistované zkušenosti pod vedením a dohledem trenéra.", "Neustálá kontrola nad procesem, zajišťování bezpečnosti, motivace, přesnější odhady, implementace intenzifikačních metod vyžadující asistenci profesionála.", "Odpovědi na veškeré otázky přímo od zdroje a v reálném čase s odůvodněním."]} />
      </div>
    </>
  );
}

export default Pricing;
