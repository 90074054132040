import DownloadFile from "../components/downloadFile";
import AboveSection from "./../components/aboveSection";
import {getDownloadURL, listAll, ref} from "firebase/storage";
import {storage} from "../firebase-config";
import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";

function DownloadableFiles() {
    const listRef = ref(storage, 'pdf/');
    const [data, setData] = useState([])

    // List All Files
    const listItem = () => {
        listAll(listRef)
            .then((res) => {
                res.items.forEach((item) => {
                    getDownloadURL(item).then((url) => {
                        setData(arr => [...arr, {url: url, name: item.name}])
                    })
                });
            }).catch((error) => {
            console.log(error)
        });
    }

    useEffect(() => {
        listItem()
    }, [])

  return (
      <>
          <Helmet>
              <title>Podklady ke cvičení</title>
              <meta
                  name="description"
                  content="Zdarma si rozklikni, nebo stáhni podklady ke cvičení."
              />
              <link rel="canonical" href="/download"/>
          </Helmet>
          <div>
              <AboveSection
                  textWhite="Naučné"
                  textRed="Soubory"
              />
              <div className="bg-white text-black text-center py-24 sm:px-[10%] px-6 grid place-items-center">
                  <h2 className="text-center text-3xl mx-auto font-bold pb-3 border-b-gym-red border-b-[3px]">
                      Fitness Soubory ke Stažení
                  </h2>
                  <div className="w-3/4 md:w-2/4 my-10">
                      <p className="text-xl font-medium">
                          Vítejte na naší stránce s fitness soubory ke stažení, kde můžete získat užitečný obsah pro
                          dosažení lepší kondice. Naše soubory obsahují tréninkové plány, tipy na zdravou výživu a další
                          užitečné materiály, které vám pomohou dosáhnout vašich fitness cílů.

                          Pokud hledáte nástroje a informace pro aktivní životní styl a zlepšení své fitness, jste na
                          správném místě. Děkujeme za vaší návštěvu a těšíme se, že vám můžeme pomoci na cestě k lepší
                          kondici a zdraví. Stačí kliknout a stáhnout si naše fitness soubory.
                      </p>
                  </div>
              </div>
              <div
                  className="grid grid-cols-1 gap-4 m-16 md:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-5 place-items-center">
                  {
                      data.map((item, id) => (<DownloadFile key={id} url={item.url} name={item.name}/>))
                  }
              </div>
          </div>
      </>
  );
}

export default DownloadableFiles;
