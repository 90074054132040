import Home from "./pages/home";
import Articles from "./pages/articles";
import Partners from "./pages/partners";
import Photogallery from "./pages/photogallery";
import TrainingPlan from "./pages/trainingPlan";
import DetailPageArticle from "./pages/detailPageArticle";
import DownloadableFiles from "./pages/downloadableFiles";
import Page404 from "./components/page404";
import { Navigate } from "react-router-dom";

export const RoutesApp = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/articles",
    element: <Articles />,
  },
  {
    path: "/partners",
    element: <Partners />,
  },
  {
    path: "/photogallery",
    element: <Photogallery />,
  },
  {
    path: "/training-plan",
    element: <TrainingPlan />,
  },
  {
    path: "/articles/:id",
    element: <DetailPageArticle />,
  },
  {
    path: "/download",
    element: <DownloadableFiles />,
  },
  {
    path: "/404",
    element: <Page404 />,
  },
  {
    path: "*",
    element: <Navigate to="/404" />,
  },
];

export const RoutesNav = [
  {
    path: "/",
    name: "O nás",
    element: <Home />,
  },
  {
    path: "/training-plan",
    name: "Trénink plán",
    element: <TrainingPlan />,
  },
  {
    path: "/photogallery",
    name: "Galerie",
    element: <Photogallery />,
  },
  {
    path: "/articles",
    name: "Články",
    element: <Articles />,
  },
  {
    path: "/download",
    name: "Soubory",
    element: <DownloadableFiles />,
  },
];
