import { initializeApp } from "firebase/app";
import {getFirestore} from "@firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: process.env.REACT_API_KEY,
    authDomain: "alesweb-b5393.firebaseapp.com",
    projectId: "alesweb-b5393",
    storageBucket: "alesweb-b5393.appspot.com",
    messagingSenderId: "136875444201",
    appId: process.env.REACT_APP_ID,
    measurementId: "G-E0ZJVYS8QW"
};

const app = initializeApp(firebaseConfig);

export const storage = getStorage(app);
export const db = getFirestore(app);