import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import {getDownloadURL, ref} from "firebase/storage";
import {storage} from "../firebase-config";
import {useEffect, useState} from "react";

const verticleTimelineData = [
  {
    text: "20 let působení ve Fitness",
  },
  {
    text: "Stovky odtrénovaných Klientů",
  },
  {
    text: "27 startů v soutěžní kulturistice",
  },
  {
    text: "Status profesionálního kulturisty od 2018",
  },
  {
    text: "Mistr ČR v mrtvém tahu 2022",
  },
  {
    text: "Certifikace z ČR a z Velké Británie",
  },
  {
    text: "Trenérská kariéra ve 3 zemích - ČR, Velká Británie, Saúdská Arábie",
  },
  {
    text: "Moderátor mezinárodních závodů ve Fitness - Diamond Cup, Fitness Mania, Mr Universe",
  },
  {
    text: "Atlet značek Czech Virus a Gymio",
  },
  {
    text: "Hostem mnoha interview ve Fitness odvětví",
  }
]

function VerticalTimecard() {

  const imgRef = ref(storage, 'images/s-11.jpg');
  const [imgUrl, setImgUrl] = useState("")

  const callImg = () => {
    getDownloadURL(imgRef)
        .then((url) => {
          setImgUrl(url)
        })
        .catch((error) => {
          console.log(error)
        });
  }

  useEffect(() => {
    callImg()
  }, [])

  return (
    <div
      className="xl-2:max-w-[700px] border-y-2 bg-cover bg-center relative"
      style={{
        backgroundImage:
          `url(${imgUrl})`,
      }}
    >
      <div className="bg-black/70 inset-0 absolute"></div>
      <VerticalTimeline>
        {
          verticleTimelineData.map((data, id) => (
              id % 2 === 0 || id === 0 ? (
                  <VerticalTimelineElement
                      key={id}
                      className="vertical-timeline-element--work drop-shadow-xl-3 font-poppins font-extrabold text-sm md:text-2xl"
                      contentStyle={{ background: "#000", color: "#fff" }}
                      date={data.year}
                      iconStyle={{ background: "#000", color: "#fff" }}
                  >
                    <h3 className="vertical-timeline-element-title text-gym-red">
                      {data.text}
                    </h3>
                    <h4 className="vertical-timeline-element-subtitle text-gym-red">
                      {data.placement}
                    </h4>
                  </VerticalTimelineElement>
              ) : (
                  <VerticalTimelineElement
                      key={id}
                      className="vertical-timeline-element--work drop-shadow-lg-3 font-poppins font-extrabold text-sm md:text-2xl "
                      contentStyle={{ background: "#FF2800", color: "#fff" }}
                      date={data.year}
                      iconStyle={{ background: "#FF2800", color: "#fff" }}
                  >
                    <h3 className="vertical-timeline-element-title ">
                      {data.text}
                    </h3>
                    <h4 className="vertical-timeline-element-subtitle ">
                      {data.placement}
                    </h4>
                  </VerticalTimelineElement>
              )
          ))
        }
      </VerticalTimeline>
    </div>
  );
}

export default VerticalTimecard;
