import exercise_512px from "../icons/exercise_512px.png";
import ReactStars from "react-rating-stars-component";
import column from "../icons/column.png";

function ReviewCard({ trainerName, numberOfStars, trainerDescription }) {
  return (
    <div className="py-16 mt-6">
      <div className="bg-dark-xd-gray p-2 md:p-6 md:pl-24  border-2 border-gym-red mx-6 sm:mx-12 lg:mx-24 xl:mx-48">
        <div className="text-2xl sm:text-4xl text-gym-red ">{trainerName}</div>
        <div className="flex flex-col sm:flex-row items-center justify-between gap-6">
          <div>
            <ReactStars
              count={5}
              value={numberOfStars}
              activeColor="#FF2800"
              size={24}
              edit={false}
            />
            <img className="h-24 w-24 " src={exercise_512px}></img>
          </div>
          <p className="text-xd-gray flex-1 sm:mr-16 p-4">{trainerDescription}</p>
          <img className="hidden md:block self-start w-6 mr-12" src={column}></img>
          <div
            className="hidden sm:block self-end w-0 h-0
                     border-l-[64px] border-l-transparent
                     border-r-[0px] border-r-transparent
                     border-b-[64px]  border-b-gym-red
                    "
          ></div>
        </div>
      </div>
    </div>
  );
}

export default ReviewCard;
