import exercise_512px from "../icons/exercise_512px.png";
import power_512px from "../icons/power_512px.png";
import calculator_512px from "../icons/calculator_512px.png";
import LandingCard from "./landingCard";

function TrainingDietCard() {
    return (
        <div className="relative pb-48 bg-[#0d0d0d]">
            <div className="grid grid-cols-1 place-items-center md:grid-cols-2 xl:grid-cols-4 gap-6 px-[10%] xl:absolute z-0 pt-6 bottom-[30px]">
                <LandingCard colorStyle={true} icon={exercise_512px} heading="Tréninkový plán" text="Strategický návod jak co nejefektivněji dosahovat výsledků zaměřený na individuální potřeby jednotlivce." />
                <LandingCard colorStyle={true} icon={calculator_512px} heading="Výživový plán" text="Konkrétní osobní přehled a popis toho, co jíst a doplňovat,v jakých množstvích, distribuci, a načasování. " />
                <LandingCard colorStyle={false} icon={power_512px} heading="Kompletní plán" text="Zahrnuje jak výživový, tak tréninkový plán na míru. Celkový průvodce všemi oblastmi metodiky na jednom místě!" />
                <LandingCard colorStyle={true} icon={exercise_512px} heading="Osobní Tréninky" text="Vedení osobního tréninku přímo v terénu posilovny. Odpovědi na veškeré otázky přímo od zdroje a v reálném čase s odůvodněním." />
            </div>
        </div>
    );
}

export default TrainingDietCard;
